@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/text-fonts/Inter-Regular.woff');
}

/* @font-face {
  font-family: 'FatFrank';
  src: url('../assets/fonts/text-fonts/FatFrank.otf');
} */

@font-face {
  font-family: 'FoundersGroteskNormal';
  src: url('../assets/fonts/text-fonts/FoundersGroteskNormal.otf');
}

@font-face {
  font-family: 'FoundersGroteskBold';
  src: url('../assets/fonts/text-fonts/FoundersGroteskBold.otf');
}

img {
  mask-image: url('../assets/twitter.svg');
  -webkit-mask-size: cover;
  -webkit-mask-repeat: round;
}


/* Header start */
.navbar-brand {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;
  letter-spacing: -0.014em;

  /* light-mode/text/primary */

  color: #2E2B26;
}

#header {
  height: 80px;
}

#navbarSupportedContent {
  justify-content: end;
}
.err_msg{

color: #EB5757;
font-family: Inter;
font-style: normal;
font-weight: bold;
font-size: 16px;line-height: 24px;
}
.navbar-light .navbar-nav .nav-link {

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 0 16px;

  position: relative;
  z-index: 1000;
  background: white;
}

/* Header end */

/* option_section start */
.options {
  list-style: none;
  display: inline-flex;
  padding: unset;
}

.option_section {
  background: #1d9bf0;
  height: 40px;
  overflow: hidden;
}

.option_section .container {
  height: 100%;
  background-color: #fff;
}

.option_active {
  background-color: #446365;
  color: #fff !important;


}

.option_item i {
  color: #446365;
}

.option_item {
  min-width: 260px;
  padding: .72rem .8rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  border-top: 1px solid #446365;
  border-right: 1px solid #446365;
  text-transform: uppercase;
  color: #1E767E;
}

/* option_section end */

.content-space-2 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.content_title {

  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  letter-spacing: -0.02em;

  padding-top: .51rem;
}

.content_upper_title {
  font-family: FoundersGroteskNormal;
  font-style: normal;
  font-weight: 600;
  font-size: 22.7793px;
  line-height: 30px;
  align-items: flex-end;
  letter-spacing: -0.014em;

  color: #46686A;
}

.content_text {

  font-family: FoundersGroteskNormal;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  padding-top: 1rem;
}

.content_sub_title {
  font-family: FoundersGroteskBold;
  font-style: normal;
  font-weight: bold;
  font-size: 22.7793px;
  line-height: 130%;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.014em;


}

.mint_form {
  padding: 2rem 1rem;
}

.mint_form .mint_box {
  border-radius: unset;
  height: 51.31px;
  left: 0px;
  top: 0px;

  background: #FFFFFF;
  border: 1px solid #C3C2C1;
  margin: 0px 10px;
  box-sizing: border-box;
}

.mint_btn {

  border-radius: unset;
  background: #1d9bf0 !important;
  border: 1px solid #ffffff !important;
  box-sizing: border-box;

  color: #ffffff !important;

  height: 51.31px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 17.7172px;
  line-height: 25px;
  white-space:nowrap;
}
.mint_bold_btn {

  border-radius: unset;
  background: #1d9bf0 !important;
  border: 1px solid #ffffff !important;
  box-sizing: border-box;

  color: #2E2B26 !important;

  height: 51.31px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 17.7172px;
  line-height: 25px;
}
.nfts_tab{
  /* border-bottom: 1px solid #C7C8C9 !important; */
}

.nfts_tab .nav-link{
  /* color: #2E2B26 !important;
  font-family: Inter;
  font-size: 22px;
  line-height: 24px;
  padding-left: unset; */
  /* padding-right: 3rem; */
  
  cursor: pointer;
}
.nfts_tab .nav-link.active{
  font-family: Inter;
  font-weight: bold;
  border-radius: unset;
  /* background-color: transparent; */
  color: #fff !important;
}
.btn-link{
  color: #2E2B26;
}


.mint_btn:active {

  background: #1d9bf0;
  border: 1px solid #ffffff;
  box-sizing: border-box;

  color: #ffffff;
}

.mint_btn:hover {

  background: #1d9bf0;
  border: 1px solid #ffffff;
  box-sizing: border-box;

  color: #ffffff;
}

.footer_area {
  padding: 3rem 0rem;
}

.footer_area ul {
  list-style: none;
  padding-left: unset;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2E2B26;
}

.footer_area ul li {
  padding-top: .5rem;
}

.copyright_text {

  font-family: Inter;
  font-size: 16px;

  color: #848484;


}

.social_media {
  justify-content: end;
}

.font-16 {

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.font-72 {

  
  font-style: normal;
  font-weight: 900;
  font-size: 72px;
  line-height: 76px;
  /* or 106% */

  letter-spacing: -0.02em;


}

.attr_type {

  color: rgba(45, 82, 85, 0.4);
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
}

.attr_value {

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
}

.green_text {
  color: #309AAC;
  text-align: start;
}

/* Mint-page */
.mint_section {

  height: 836px;
  left: 0px;
  top: 0px;

}

.Group_img_1 {
  position: relative;
  top: 86px;
  left: -10px;
  z-index: 0;
}

.Group_img_2 {

  position: relative;
  top: 340px;
  left: -165px;
}

.Group_img_3 {

  position: relative;
  top: 450px;
  left: -8px;
}

.Group_img_4 {

  position: relative;
  top: 10px;
  left: 510px;
}

/* Mint-page end */

/* landing page start */
.landing_section {
  height: 600px;
  background: linear-gradient(180deg, rgba(253, 255, 255, 0) 63.53%, #FBFFFF 76.42%), radial-gradient(62.76% 62.76% at 50% 37.24%, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), url("../assets/landing.png"), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #F3FEFF;
  background-position: top;
  background-size: 100%;
}

/* landing page end */

.FounderGroteskFont-18 {

  font-family: FoundersGroteskNormal;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */


  color: rgba(45, 82, 85, 0.8);
}

.green_sub_heading {
  font-family: FoundersGroteskNormal;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  display: flex;
  align-items: center;
  letter-spacing: -0.014em;

  color: rgba(30, 118, 126, 0.7);

}

.heading1 {
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  /* or 120% */

  letter-spacing: -0.02em;

  color: #446365;
}

.distribution_section {

  background: linear-gradient(180deg, #FFFFFF 0%, #F3FEFF 100%);

  /* height: 600px; */
}

.distribution_section_text {


  font-family: FoundersGroteskNormal;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */

  color: rgba(45, 82, 85, 0.8);

}

.distribution_feature_title {

  
  color: #446365;
  letter-spacing: -0.014em;
  font-size: 18px;
  line-height: 26px;

}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-24 {
  font-size: 24px !important;
}

.join_academy {
  background: #F3FEFF;
}

.font_446365 {
  font-weight: normal;
  font-family: FoundersGroteskNormal;
  color: #446365;
}

.roadmap_list {
  list-style-type: none;
  padding: unset;
}

.set_opacity {
  opacity: .3;
}

.member_name {

  
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  letter-spacing: -0.014em;

  color: #446365;
}

.member_desig {
  font-family: FoundersGroteskNormal;

  font-size: 18px;
  line-height: 26px;
  color: #446365;

  opacity: 0.8;
}

.nft_btn {

  font-family: Inter;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;

  padding: 1rem 2rem 1rem 0rem !important;
  border: 1px solid #C3C2C1;

  background: #fff;
  letter-spacing: -0.014em;
  color: #2E2B26;
  border-radius: unset;
  text-align: left;
}
.share_btn{
  height: 51.31px;
  font-family: Inter;
  font-style: normal;
  font-size: 17.7172px;
  background: #fff;
}
.nft_btn:focus {
  background: transparent !important;

  color: #2E2B26;
}

.nft_btn:active {
  background: transparent !important;

  color: #2E2B26;
}

.nft_btn:hover {
  background: transparent !important;
  color: #2E2B26;
}

.nft_btn.active {
  background: transparent !important;
  /* background: #1d9bf0; */
  font-weight: bold;
  font-size: 22px;
  border: 1px solid #C3C2C1;
  letter-spacing: -0.014em;
  color: #2E2B26;
  /* border-bottom: 2px solid #446365; */

  border-bottom: 2px solid #446365 !important;
}

.nft_looks_label {

  background: #DBE9EB;
  /* ink/base */

  border: 0.583871px solid #909193;
  box-sizing: border-box;
  border-radius: 2.80565px;

  color: #000000;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 8.67373px;
  line-height: 12px;
  padding: 4.67097px;
}

.nft_token {

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;


  text-transform: capitalize;
}

.trait_font {

  color: #2E2B26;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14.227px;
}

.err_box {
  background-color: #FFF3F3 !important;
}

.err_box::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1;
  /* Firefox */
}

.err_box:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

.err_box::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}

#navbarSupportedContent .nav-item .nav-link.active {
  background: #1d9bf0;
  border: 1px solid #ffffff;
  color: #ffffff;
}

.stack_btn:hover {
  color: #fff;
}

.stack_btn {
  background: #4D7275;
  color: #fff;
  border-radius: unset;

  font-family: FoundersGroteskNormal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  min-width:135px;
}

.stack_area {
  background: #1d9bf0;
}

.stack_text {
  color: rgba(45, 82, 85, 0.8);

  margin: 0px 10px;
  font-family: FoundersGroteskNormal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
}

/* Responsive view  start*/

@media (max-width: 767px) {

  .gallery_mobile_image{
    position:absolute;
  }

 

  .option_section {

    overflow: auto;
  }

  .main-section .content-space-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .mint_form .mint_box {
    margin: 1rem 0 !important;
  }

  .nft_img {
    width: 75%;
  }

  .Group_img_4 {
    display: none !important;
    visibility: hidden;
  }

  .font-72 {
    
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    line-height: 48px;
    letter-spacing: -0.02em;
    padding-top: 5rem;
  }

  .main-section .content p {
    width: 100% !important;
    text-align: justify;
  }

  .landing_section {

    height: 400px;
    background-size: cover;
  }

  .landing_section .font-72 {
    width: 100% !important;
    font-size: 32px;
    padding-top: 2rem;
    line-height: 32px;
  }

  .content-space-2 {

    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .landing_section .container {
    padding-top: .2rem !important
  }

  .landing_section .container .row {
    padding: 1.752rem !important
  }

  .w-50 {
    width: 100% !important;
  }

  .Group_img_1 {
    position: absolute;
    top: 134px;
    left: -66px;
    z-index: 0;
  }

  .mint_section {
    height: 300px !important;
    /* padding-bottom:10rem; */
  }

  .mint_section .card {

    position: relative;
    top: -103px;
    margin-top: 0px;
  }

  .mint_section .content {
    position: relative;
    top: -140px;
  }

  .btn {
    margin: 1rem 0rem;
  }
}

@media (min-width: 1400px) {}

@media only screen and (min-width: 1824px) {}

/* Responsive view end */

.py-59{
  padding-top: .59rem !important;
}

